var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prodivers-list" },
    [
      [
        _c(
          "div",
          [
            _c("h3", { staticStyle: { "margin-bottom": "30px" } }, [
              _vm._v(" Checagem de cnh "),
            ]),
            _c(
              "div",
              {
                staticClass: "filters",
                staticStyle: { "margin-bottom": "30px" },
              },
              [
                _c(
                  "b-row",
                  { staticStyle: { "align-items": "end" } },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("b", [_vm._v("CPF:")]),
                        _c("b-input", {
                          attrs: { placeholder: "cpf do motorista" },
                          model: {
                            value: _vm.filters.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "document", $$v)
                            },
                            expression: "filters.document",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("b", [_vm._v("CNH:")]),
                        _c("b-input", {
                          attrs: { placeholder: "cnh do motorista" },
                          model: {
                            value: _vm.filters.cnh,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "cnh", $$v)
                            },
                            expression: "filters.cnh",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("b", [_vm._v("Nome:")]),
                        _c("b-input", {
                          attrs: { placeholder: "nome do motorista" },
                          model: {
                            value: _vm.filters.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "name", $$v)
                            },
                            expression: "filters.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-btn",
                          {
                            attrs: { variant: "primary" },
                            on: { click: _vm.search },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-search",
                              staticStyle: { "margin-right": "5px" },
                            }),
                            _c("span", [_vm._v("Buscar")]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("b-table", {
              attrs: {
                striped: "",
                hover: "",
                items: _vm.items.data,
                fields: _vm.fields,
                busy: _vm.loading,
                responsive: "sm",
                "selected-variant": "secondary",
                selectable: "",
              },
              on: { "row-clicked": _vm.onRowSelected },
              scopedSlots: _vm._u([
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center text-dark my-2" },
                        [
                          _c("b-spinner", { staticClass: "align-middle mr-2" }),
                          _c("strong", [_vm._v("Carregando...")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(cpf)",
                  fn: function (data) {
                    return [
                      _c("b", { staticClass: "text-primary" }, [
                        _vm._v(" " + _vm._s(_vm.getCPF(data)) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(cnhnumber)",
                  fn: function (data) {
                    return [
                      _c("b", { staticClass: "text-danger" }, [
                        _vm._v(" " + _vm._s(_vm.getCNH(data)) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(driver)",
                  fn: function (data) {
                    return [_vm._v(" " + _vm._s(_vm.getName(data)) + " ")]
                  },
                },
                {
                  key: "cell(status)",
                  fn: function (data) {
                    return [
                      _c(
                        "b-badge",
                        {
                          attrs: {
                            pill: "",
                            variant: _vm.getStatusStyle(data.item.status)
                              .variant,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getStatusStyle(data.item.status).label
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(providerData)",
                  fn: function (data) {
                    return [_vm._v(" " + _vm._s(_vm.getValidDate(data)) + " ")]
                  },
                },
                {
                  key: "cell(createdAt)",
                  fn: function (data) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.formatDate(data.item.createdAt)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("b-pagination", {
              staticStyle: { "justify-content": "center" },
              attrs: {
                "total-rows": _vm.pagination.rows,
                "per-page": _vm.pagination.perPage,
                "first-number": "",
              },
              on: { change: _vm.getDriverDocument },
              model: {
                value: _vm.pagination.currentPage,
                callback: function ($$v) {
                  _vm.$set(_vm.pagination, "currentPage", $$v)
                },
                expression: "pagination.currentPage",
              },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }