<template>
  <div class="prodivers-list">
    <template>
      <div>
        <h3 style="margin-bottom: 30px">
          Checagem de cnh
        </h3>
        <div class="filters" style="margin-bottom: 30px;">
          <b-row style="align-items: end;">
            <b-col cols="3">
              <b>CPF:</b>
              <b-input v-model="filters.document" placeholder="cpf do motorista" />
            </b-col>
            <b-col cols="3">
              <b>CNH:</b>
              <b-input v-model="filters.cnh" placeholder="cnh do motorista" />
            </b-col>
            <b-col cols="3">
              <b>Nome:</b>
              <b-input v-model="filters.name" placeholder="nome do motorista" />
            </b-col>
            <b-col>
              <b-btn variant="primary" @click="search">
                <i class="fa fa-search" style="margin-right: 5px;" />
                <span>Buscar</span>
              </b-btn>
            </b-col>
          </b-row>
        </div>
        <b-table striped hover
                 :items="items.data"
                 :fields="fields"
                 :busy="loading"
                 responsive="sm"
                 selected-variant="secondary"
                 selectable
                 @row-clicked="onRowSelected"
        >
          <template v-slot:table-busy>
            <div class="text-center text-dark my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>Carregando...</strong>
            </div>
          </template>
          <template #cell(cpf)="data">
            <b class="text-primary">
              {{ getCPF(data) }}
            </b>
          </template>
          <template #cell(cnhnumber)="data">
            <b class="text-danger">
              {{ getCNH(data) }}
            </b>
          </template>
          <template #cell(driver)="data">
            {{ getName(data) }}
          </template>
          <template #cell(status)="data">
            <b-badge pill :variant="getStatusStyle(data.item.status).variant">
              {{ getStatusStyle(data.item.status).label }}
            </b-badge>
          </template>
          <template #cell(providerData)="data">
            {{ getValidDate(data) }}
          </template>
          <template #cell(createdAt)="data">
            {{ formatDate(data.item.createdAt) }}
          </template>
        </b-table>
        <b-pagination
          v-model="pagination.currentPage"
          style="justify-content: center;"
          :total-rows="pagination.rows"
          :per-page="pagination.perPage"
          first-number
          @change="getDriverDocument"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'CNHCheckerTable',
  props: {
    showModal: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      filters: {
        document: '',
        cnh: '',
        name: ''
      },
      fields: [
        { key: 'cpf', label: 'cpf' },
        { key: 'cnhnumber', label: 'cnh' },
        { key: 'status', label: 'status' },
        { key: 'driver', label: 'nome' },
        { key: 'providerData', label: 'validade' },
        { key: 'createdAt', label: 'subiu em' },
      ],
      items: [],
      pagination: {
        rows: 1000,
        perPage: 10,
        currentPage: 1
      },
      loading: false,
    }
  },
  created() {
    this.getDriverDocument();
  },
  methods: {
    getCPF(data) {
      return this.formatCPF(data?.item?.providerData?.DocInfo?.CPF)
    },
    getCNH(data) {
      return data?.item?.providerData?.DocInfo?.CNHNUMBER || 'N/A'
    },
    getName(data) {
      return data?.item?.providerData?.DocInfo?.NAME || 'N/A'
    },
    getValidDate(data) {
      return data?.item?.providerData?.DocInfo?.VALIDDATE || 'N/A'
    },
    async getDriverDocument(pagination) {
      this.loading = true;
      try {
        const { document, cnh, name } = this.filters;
        const { perPage } = this.pagination;
        const paginationNumber = typeof pagination === 'number' ? pagination - 1 || 0 : 0;
        let url = `/documents/list?status=pending_approval&take=${perPage}&offset=${paginationNumber}`;

        if (document) url += `&document=${document}`;
        if (cnh) url += `&cnh=${cnh}`;
        if (name) url += `&name=${name}`;

        const res = await this.$fleetRegulationProvider.get(url)
        this.items = res.data
        this.pagination.rows = res.data.count
      } catch (err) {
        console.error(err.message, { ...err });
      } finally {
        this.loading = false;
      }
    },
    search() {
      this.pagination.currentPage = 1;
      this.getDriverDocument();
    },
    getStatusStyle(name) {
      const status = {
        pending: { variant: 'warning', label: 'PENDENTE' },
        approved: { variant: 'positive', label: 'APROVADO' },
        rejected: { variant: 'warning', label: 'REJEITADO' },
        not_found: { variant: 'warning', label: 'NĀO ENCONTRADO' },
        force_update: { variant: 'warning', label: 'FORCE UPDATE' },
        pending_approval: { variant: 'warning', label: 'PENDENTE DE APROVAÇĀO' },
      }
      return status[name];
    },
    formatDate(date) {
      return format(date, 'DD/MM/YYYY HH:mm')
    },
    formatCPF(cpf) {
      if (!cpf) return 'N/A'
      cpf = cpf.replace(/[^\d]/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    onRowSelected(selected) {
      this.showModal(selected);
    }
  }
};
</script>

<style lang="sass" scoped>
.vuetable-th-slot-actions
  width: 30px
.vuetable-th-slot-status
  width: 150px
.edit-button
  padding: 0
  margin: 0
.badge
  padding: 5px 10px
  color: white
  &-ACTIVE
    background: green
  &-PENDING
    background: orange
  &-CLOSED
    background: red
</style>
