var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _vm.showModal
        ? _c("cnh-checker-header", {
            attrs: {
              show: _vm.showModal,
              data: _vm.row,
              "set-modal": _vm.setModal,
              "find-document": _vm.findDocument,
            },
          })
        : _vm._e(),
      _c("cnh-checker-table", {
        ref: "cnhCheckerTable",
        attrs: { "show-modal": _vm.setModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }