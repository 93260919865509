var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            visible: true,
            title: "Confira as Informações da CNH",
            size: "xl",
            "hide-footer": "",
            scrollable: "",
            "header-bg-variant": "white",
          },
          on: { change: () => _vm.setModal({}) },
        },
        [
          _c(
            "b-card",
            {
              staticClass: "overflow-hidden p-0 m-0",
              staticStyle: { "max-width": "100%" },
              attrs: { "no-body": "", "border-variant": "white" },
            },
            [
              _vm.loading
                ? _c("div", { staticClass: "text-center" }, [
                    _c("h1", [
                      _c("i", {
                        staticClass: "fa fa-spin fa-refresh",
                        attrs: { variant: "success" },
                      }),
                    ]),
                    _c("p", [_vm._v("Carregando...")]),
                  ])
                : _c(
                    "b-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "5", xl: "5" } },
                        [
                          _vm.imageB64.startsWith("data:application/pdf;")
                            ? _c("object", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  data: _vm.imageB64,
                                  type: "application/pdf",
                                },
                              })
                            : _vm.imageB64
                            ? _c("b-card-img", {
                                ref: "cardImage",
                                staticClass: "rounded-0 img-zoomable",
                                attrs: { src: _vm.imageB64, alt: "Image" },
                                on: {
                                  load: _vm.rotateImage,
                                  click: _vm.handleMouseMove,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "bg-white",
                          attrs: { sm: "12", md: "12", lg: "7", xl: "7" },
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-card-text",
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "p-3" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticStyle: { "margin-top": "1rem" },
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                            },
                                            [_vm._v(" Dados da CNH ")]
                                          ),
                                          _c("hr", {
                                            staticStyle: {
                                              border: "none",
                                              "border-top":
                                                "1px dashed darkgray",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("Nome:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getName)),
                                          ]),
                                          _c("br"),
                                          _c(
                                            "b",
                                            { staticClass: "mr-1 text-danger" },
                                            [_vm._v("Nome Rental:")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getNameRental)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("CPF:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getCpf)),
                                          ]),
                                          _c("br"),
                                          _c(
                                            "b",
                                            { staticClass: "mr-1 text-danger" },
                                            [_vm._v("CPF Rental:")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getCpfRental)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticClass: "mr-1 text-warning",
                                            },
                                            [_vm._v("Nº Registro:")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getCnhNumber)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("Doc Identidade:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getIdentificationNumber
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("Emissor:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getOrgEmission)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("Nascimento:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.getBirthDate)),
                                          ]),
                                          _c("br"),
                                          _c(
                                            "b",
                                            { staticClass: "mr-1 text-danger" },
                                            [_vm._v("Nascimento Rental:")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.getBirthDateRental)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "b",
                                              {
                                                staticClass:
                                                  "mr-1 text-warning",
                                              },
                                              [_vm._v("Validade:")]
                                            ),
                                            _c("strong", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "texts font-weight-bold",
                                                  attrs: {
                                                    id: "clip-expiration-date",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getValidDate ||
                                                          _vm.$i18n.t(
                                                            "fleet.texts.noInfoFound"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm.getValidDate !== "N/A"
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "link",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.copyDate(
                                                                _vm.getValidDate
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-clone",
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("Data de Expedição:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.getExpeditionDate)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("Primeira Habilitação:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getFirstQualificationDate
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("b-col", { attrs: { cols: "12" } }, [
                                        _c("hr", {
                                          staticStyle: {
                                            border: "none",
                                            "border-top": "1px dashed darkgray",
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("Subiu em:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatDate(
                                                  _vm.data.createdAt
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("b", { staticClass: "mr-1" }, [
                                            _vm._v("Atualizou em:"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatDate(
                                                  _vm.data.updatedAt
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                "margin-top": "20px",
                                              },
                                              attrs: {
                                                size: "sm",
                                                variant: "success",
                                                disabled:
                                                  _vm.imageB64.startsWith(
                                                    "data:application/pdf;"
                                                  ),
                                              },
                                              on: { click: _vm.handleRotate },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-rotate",
                                              }),
                                              _vm._v(" Rotacionar Imagem "),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: `/drivers/profile/${_vm.data.driverId}`,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-btn",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                    "margin-top": "20px",
                                                  },
                                                  attrs: {
                                                    size: "sm",
                                                    variant: "outline-primary",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Abrir Perfil do Motorista "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "span d-flex flex-row",
                                          staticStyle: {
                                            "margin-top": "40px",
                                            "align-items": "flex-end",
                                          },
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticClass:
                                                "mr-2 mb-2 text-danger",
                                            },
                                            [_vm._v("Atualizar Validade*:")]
                                          ),
                                          _c("b-input", {
                                            staticStyle: { width: "50%" },
                                            attrs: { type: "date" },
                                            on: { paste: _vm.handlePaste },
                                            model: {
                                              value:
                                                _vm.form.license_expired_at,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "license_expired_at",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.license_expired_at",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isPendingApproval
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            "margin-top": "40px",
                                          },
                                        },
                                        [
                                          _c("hr"),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mr-5",
                                              attrs: {
                                                variant: "danger",
                                                size: "lg",
                                                title: "Rejeitar",
                                              },
                                              on: { click: _vm.rejectAction },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-close",
                                              }),
                                              _vm._v(" Reprovar "),
                                            ]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "success",
                                                size: "lg",
                                                title:
                                                  !_vm.form
                                                    .license_expired_at &&
                                                  "Atualize uma data de validade",
                                                disabled:
                                                  !_vm.form
                                                    .license_expired_at ||
                                                  _vm.isExpired(),
                                              },
                                              on: { click: _vm.approveAction },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-check",
                                              }),
                                              _vm._v(" Aprovar "),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }