<template>
  <Layout>
    <cnh-checker-header v-if="showModal" :show="showModal" :data="row"
                        :set-modal="setModal" :find-document="findDocument"
    />
    <cnh-checker-table ref="cnhCheckerTable" :show-modal="setModal" />
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import CnhCheckerHeader from '@components/drivers/cnh-checker/cnh-checker-header';
import CnhCheckerTable from '@components/drivers/cnh-checker/cnh-checker-table';

export default {
  name: 'IndexProviders',
  components: {
    Layout,
    CnhCheckerHeader,
    CnhCheckerTable,
  },
  data() {
    return {
      showModal: false,
      row: {}
    };
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      this.findDocument(id).then((res) => {
        this.setModal(res.data)
      }).catch(console.log)
    }
  },
  methods: {
    setModal (row, showModal) {
      this.row = row;
      this.showModal = showModal || !this.showModal;
      const url = window.location.href.split('cnh-checker')[0]
      history.pushState(null, null, `${url}cnh-checker/${!row.id  ? '' : row.id}`)
    },
    async findDocument(id) {
      return await this.$fleetRegulationProvider.get(`/documents/${id}`)
    },
  }
};
</script>
