<template>
  <div>
    <b-modal :visible="true" title="Confira as Informações da CNH" size="xl"
             hide-footer scrollable header-bg-variant="white"
             @change="() => setModal({})"
    >
      <b-card no-body class="overflow-hidden p-0 m-0" style="max-width: 100%;"
              border-variant="white"
      >
        <div
          v-if="loading"
          class="text-center"
        >
          <h1><i class="fa fa-spin fa-refresh" variant="success" /></h1>
          <p>Carregando...</p>
        </div>
        <b-row v-else no-gutters>
          <b-col sm="12" md="12" lg="5"
                 xl="5"
          >
            <object v-if="imageB64.startsWith('data:application/pdf;')"
                    style="width: 100%; height: 100%" :data="imageB64"
                    type="application/pdf"
            />
            <b-card-img v-else-if="imageB64" ref="cardImage" :src="imageB64"
                        alt="Image"
                        class="rounded-0 img-zoomable"
                        @load="rotateImage"
                        @click="handleMouseMove"
            />
          </b-col>
          <b-col sm="12" md="12" lg="7"
                 xl="7"
                 class="bg-white"
          >
            <b-card-body>
              <b-card-text>
                <b-row class="p-3">
                  <b-col cols="12" style="margin-top: 1rem">
                    <h3 style="text-align: center;">
                      Dados da CNH
                    </h3>
                    <hr style="border: none; border-top: 1px dashed darkgray">
                  </b-col>
                  <b-col cols="12" class="span">
                    <b class="mr-1">Nome:</b>
                    <span>{{ getName }}</span>
                    <br>
                    <b class="mr-1 text-danger">Nome Rental:</b>
                    <span>{{ getNameRental }}</span>
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1">CPF:</b>
                    <span>{{ getCpf }}</span>
                    <br>
                    <b class="mr-1 text-danger">CPF Rental:</b>
                    <span>{{ getCpfRental }}</span>
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1 text-warning">Nº Registro:</b>
                    <span>{{ getCnhNumber }}</span>
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1">Doc Identidade:</b>
                    <span>{{ getIdentificationNumber }}</span>
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1">Emissor:</b>
                    <span>{{ getOrgEmission }}</span>
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1">Nascimento:</b>
                    <span>{{ getBirthDate }}</span>
                    <br>
                    <b class="mr-1 text-danger">Nascimento Rental:</b>
                    <span>{{ getBirthDateRental }}</span>
                  </b-col>
                  <b-col cols="6" class="span">
                    <div>
                      <b class="mr-1 text-warning">Validade:</b>
                      <strong>
                        <span id="clip-expiration-date" class="texts font-weight-bold">
                          {{ getValidDate || $i18n.t('fleet.texts.noInfoFound') }}
                          <b-button v-if="getValidDate !== 'N/A'" variant="link" size="sm"
                                    @click="copyDate(getValidDate)"
                          >
                            <i class="fa fa-clone" aria-hidden="true" />
                          </b-button>
                        </span>
                      </strong>
                    </div>
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1">Data de Expedição:</b>
                    <span>{{ getExpeditionDate }}</span>
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1">Primeira Habilitação:</b>
                    <span>{{ getFirstQualificationDate }}</span>
                  </b-col>
                  <b-col cols="12">
                    <hr style="border: none; border-top: 1px dashed darkgray">
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1">Subiu em:</b>
                    <span>{{ formatDate(data.createdAt) }}</span>
                  </b-col>
                  <b-col cols="6" class="span">
                    <b class="mr-1">Atualizou em:</b>
                    <span>{{ formatDate(data.updatedAt) }}</span>
                  </b-col>
                  <b-col cols="6">
                    <b-btn size="sm" variant="success" style="width: 100%; margin-top: 20px"
                           :disabled="imageB64.startsWith('data:application/pdf;')" @click="handleRotate"
                    >
                      <i class="fa fa-rotate" />
                      Rotacionar Imagem
                    </b-btn>
                  </b-col>
                  <b-col cols="6">
                    <router-link :to="`/drivers/profile/${data.driverId}`" target="_blank">
                      <b-btn size="sm" variant="outline-primary" style="width: 100%; margin-top: 20px">
                        Abrir Perfil do Motorista
                      </b-btn>
                    </router-link>
                  </b-col>
                  <b-col cols="12" class="span d-flex flex-row" style="margin-top: 40px; align-items: flex-end">
                    <b class="mr-2 mb-2 text-danger">Atualizar Validade*:</b>
                    <b-input v-model="form.license_expired_at" style="width: 50%" type="date"
                             @paste="handlePaste"
                    />
                  </b-col>
                </b-row>
                <div v-if="isPendingApproval" style="text-align: center; margin-top: 40px;">
                  <hr>
                  <b-button variant="danger" class="mr-5" size="lg"
                            title="Rejeitar" @click="rejectAction"
                  >
                    <i class="fa fa-close" />
                    Reprovar
                  </b-button>
                  <b-button variant="success" size="lg"
                            :title="!form.license_expired_at && 'Atualize uma data de validade'"
                            :disabled="!form.license_expired_at || isExpired()"
                            @click="approveAction"
                  >
                    <i class="fa fa-check" />
                    Aprovar
                  </b-button>
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { format, isValid } from 'date-fns';
import gql from 'graphql-tag';

export default {
  name: 'CNHCheckerHeader',
  props: {
    setModal: {
      type: Function,
      required: true,
    },
    data: {
      required: false,
      type: Object,
    },
    findDocument: {
      required: true,
      type: Function,
    }
  },
  data() {
    return {
      form: {
        license_expired_at: null,
      },
      zoomOrigin: '0% 0%',
      imgLoaded: false,
      x: 0,
      y: 0,
      imageB64: null,
      loading: false,
    };
  },
  apollo: {
    driver: {
      query: gql `
        query DriverGET($id: ID!) {
          driver(id: $id) {
            id
            name
            cpf
            birthdate
            mother_name
            father_name
          }
        }
      `,
      variables() {
        return {
          id: this.data.driverId,
        };
      },
      update(data) {
        const {
          driver
        } = data;
        return driver;
      },
      error() {
        this.$router.push({ path: '/auth/login' });
      }
    },
  },
  computed: {
    isPendingApproval() {
      return this.data.status === 'pending_approval'
    },
    getName() {
      return this.data?.providerData?.DocInfo?.NAME || 'N/A';
    },
    getNameRental() {
      return this.driver?.name || 'N/A'
    },
    getCpf() {
      return this.formatCPF(this.data?.providerData?.DocInfo?.CPF) || 'N/A';
    },
    getCpfRental() {
      return this.formatCPF(this.driver?.cpf) || 'N/A';
    },
    getCnhNumber() {
      return this.data?.providerData?.DocInfo?.CNHNUMBER || 'N/A';
    },
    getIdentificationNumber() {
      return this.data?.providerData?.DocInfo?.IDENTIFICATIONNUMBER || 'N/A';
    },
    getOrgEmission() {
      return this.data?.providerData?.DocInfo?.ORGEMISSION || 'N/A'
    },
    getBirthDate() {
      return this.data?.providerData?.DocInfo?.BIRTHDATE || 'N/A'
    },
    getBirthDateRental() {
      return this.driver?.birthdate ? format(this.driver?.birthdate, 'DD/MM/YYYY') : 'N/A'
    },
    getValidDate() {
      return this.data?.providerData?.DocInfo?.VALIDDATE || 'N/A'
    },
    getExpeditionDate() {
      return this.data?.providerData?.DocInfo?.EXPEDITIONDATE || 'N/A'
    },
    getFirstQualificationDate() {
      return this.data?.providerData?.DocInfo?.FIRSTQUALIFICATIONDATE || 'N/A'
    }
  },
  mounted() {
    this.getB64().then(async (res) => {
      this.imageB64 = res
    })
  },
  methods: {
    rotateImage() {
      const cardImage = this.$refs.cardImage;
      cardImage.style.transformOrigin = 'center';
      if (cardImage.naturalWidth > cardImage.naturalHeight && this.imgLoaded) {
        this.imgLoaded = true;
        this.rotacionarImagemBase64(this.imageB64, this.rotate).then((res) => this.imageB64 = res)
      }
    },
    handleRotate() {
      this.rotacionarImagemBase64(this.imageB64, 90).then((res) => this.imageB64 = res)
    },
    handleMouseMove(event) {
      const cardImage = this.$refs.cardImage;

      const isZoomed = cardImage.classList.contains('img-zoomable-click');

      const container = event.target;
      const rect = container.getBoundingClientRect();

      if (!isZoomed) {
        this.x = ((event.clientX - rect.left) / container.clientWidth) * 100;
        this.y = ((event.clientY - rect.top) / container.clientHeight) * 100;
      }

      cardImage.style.transformOrigin = `${this.x}% ${this.y}%`;
      cardImage.style.transform = !isZoomed ? `scale(4.0)` : `scale(1.0)`;
      cardImage.classList.toggle('img-zoomable-click');
    },
    formatDate(date) {
      return format(date, 'DD/MM/YYYY HH:mm')
    },
    formatCPF(cpf) {
      if (!cpf) return 'N/A'
      cpf = cpf.replace(/\D/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    async getB64() {
      return new Promise((resolve) => {
        fetch(this.data.linkS3)
          .then(response => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onload = (e)  => {
              const base64Data = e.target.result;
              return resolve(base64Data)
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => console.error('Error fetching image:', error));
      })
    },
    rotacionarImagemBase64(base64, graus) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = (graus % 180 === 0) ? img.width : img.height;
          canvas.height = (graus % 180 === 0) ? img.height : img.width;

          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate((graus * Math.PI) / 180);
          ctx.drawImage(img, -img.width / 2, -img.height / 2);

          const rotatedBase64 = canvas.toDataURL('image/jpeg');

          resolve(rotatedBase64);
        };

        img.onerror = function () {
          reject(new Error('Erro ao carregar a imagem.'));
        };

        img.src = base64;
      });
    },
    modalAction({ title, message }) {
      return new Promise((resolve, reject) => {
        this.$bvModal.msgBoxConfirm(message, {
          title: title,
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelVariant: 'danger',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
          .then(value => resolve(value))
          .catch((err) => reject(err))
      })
    },
    async approveAction() {
      const canApprove = await this.modalAction({
        title: 'Aprovar Documento',
        message: `Voce deseja APROVAR documento de ${this.getName}`
      })
      if (!canApprove) return false;

      try {
        const result = await this.$fleetRegulationProvider.patch(
          `/documents/approve/${this.data.id}?license_expired_at=${this.form.license_expired_at}`, {}
        )
        await this.$swal({
          type: 'success',
          title: 'CNH APROVADA!',
          showConfirmButton: false,
          timer: 2500,
        })
        this.form.license_expired_at = ''
        this.loadNewDocument(result.data.id)
      } catch(err) {
        await this.$swal({
          type: 'error',
          title: 'Ocorreu um erro ao APROVAR!',
          text: 'Verifique as informações novamente',
          showConfirmButton: false,
          timer: 2500,
        });
        console.error(err);
      }
    },
    async rejectAction() {
      const canReject = await this.modalAction({
        title: 'Rejeitar Documento',
        message: `Voce deseja REJEITAR documento de ${this.getName}`
      })
      if (!canReject) return false;

      try {
        const result = await this.$fleetRegulationProvider.patch(`/documents/reject/${this.data.id}`, {})
        await this.$swal({
          type: 'success',
          title: 'CNH REJEITADA!',
          showConfirmButton: false,
          timer: 2500,
        })
        this.form.license_expired_at = ''
        this.loadNewDocument(result.data.id)
      } catch(err) {
        await this.$swal({
          type: 'error',
          title: 'Ocorreu um erro ao REJEITAR!',
          text: 'Verifique as informações novamente',
          showConfirmButton: false,
          timer: 2500,
        });
        console.error(err);
      }
    },
    loadNewDocument(documentId) {
      this.loading = true
      this.findDocument(documentId)
        .then(async (res) => {
          await this.setModal(res.data, true);
          this.imageB64 = await this.getB64()
        })
        .catch(console.log)
        .finally(() => {
          this.loading = false
        });
    },
    copyDate (value) {
      const [day, month, year] = value.split('/')
      navigator.clipboard.writeText(`${year}-${month}-${day}`)
        .then(() => {
          this.$snotify.success(this.$t('payments.labels.clipboardSuccess'));
        })
        .catch(() => {
          this.$snotify.error(this.$t('payments.labels.clipboardError'));
        });
    },
    handlePaste(event) {
      let dateCopy = event.clipboardData.getData('text').toString()
      if (dateCopy.includes('/')) {
        const [day, month, year] = dateCopy.split('/')
        dateCopy = `${year}-${month}-${day}`
      }
      if (!isValid(new Date(dateCopy))) return this.$snotify.error('Cole uma data valida')

      this.form.license_expired_at = dateCopy
    },
    isExpired() {
      const driverLicenseExpiredAt = new Date(this.form.license_expired_at);
      const today = new Date();

      if (driverLicenseExpiredAt.getFullYear() > today.getFullYear() + 10) return true;
      if (driverLicenseExpiredAt < new Date().setDate(today.getDate() - 30)) return true;

      return false;
    }
  }
};
</script>

<style scoped>
  .span {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .img-zoomable {
    cursor: zoom-in;
    transition: transform 0.3s ease;
  }

  .img-zoomable-click {
    cursor: zoom-out !important;
  }
</style>
